import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {useDispatch, useSelector} from "react-redux"

import CheckboxInput from "../welcome/checkboxInput"
import BankTransferModal from "./bankTransferModal"
import CreditCardModal from "./creditCardModal"
import RefundModal from "./refundModal"
import {isDefined} from "../../../utils/data"
import {onChange, createGroup} from "../../../redux/group/groupAction"
import TermsOfSales from "../../../components/termsOfSales/termsOfSales2"

const Payment = ({total}) => {
    const dispatch  = useDispatch()
    const  data   = useSelector(({groupReducer}) => groupReducer)
    const {coordinator, stripe}  = useSelector(({groupReducer}) => groupReducer)
    const {members}  = useSelector(({groupReducer}) => groupReducer)    
    const  {information}   = useSelector(({groupReducer}) => groupReducer)

    const registration = (payment = false) => {
        dispatch(createGroup(data, payment === true ? 1 : 0))
    }

    return (
        <div className={"sr-card summary-block payment"}>
        {members.length > 1 ?
    <>

        {isDefined(coordinator) ? 
            <div className={"summary-header"}>
                <a className={"btn btn-sm btn-warning"} href={"https://theunion.urevent.fr/documents/group_pro_forma/" + coordinator.group.idgroup} target="_blank">Download Proforma</a><br/>If you require a fiscal invoice please request it by email through: registration-theunion@urevent.fr
            </div>
            :
            <>
                <div className={"summary-header pb-3"}>
                    After saving your group you will automatically receive a pro forma invoice.<br/>If you require a fiscal invoice please request it by email through: registration-theunion@urevent.fr
                </div>
                <CheckboxInput placeholder={"I understand this is how The Union will use my data"}
                               onChange={e => dispatch(onChange(e, true))}
                               name={"group_cgv"}
                               value={information.group_cgv.value}/>
            </>
        }
            <hr/>
            <div className={"summary-body"}>
                {isDefined(coordinator) ? isDefined(coordinator.group.group_payments) ?
                    parseFloat(coordinator.group.group_payments) < parseFloat(total) ?
                        <>
                            <div className={"row title-payment"}>
                                Payment
                            </div>
                            <div className={"row"}>
                                <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={total}/></button>
                            </div>
                            <div className={"row"}>
                                <button className={"btn btn-warning"}><BankTransferModal/></button>
                            </div>
                            <div className={"row"}>
                                    or
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"confirmation-record"}>
                                        <button className={"btn btn-warning"} onClick={() => registration()}>Save group</button>
                                    </Link>
                                </div>    
                        </>
                        :
                        parseFloat(coordinator.group.group_payments) > parseFloat(total) ?
                            <>
                                <div className={"row title-payment"}>
                                    Refund
                                </div>
                                <div className={"row"}>
                                    <button className={"btn btn-warning"}><RefundModal/></button>
                                </div>
                <div className={"row"}>
                                    or
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"confirmation-record"}>
                                        <button className={"btn btn-warning"} onClick={() => registration()}>Save group</button>
                                    </Link>
                                </div>    
                            </>
                            : "" : "" : 
                    <>

                      
                                <div className={"row mt-3 p-3"}>
                                    <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={total}/></button>
                                </div>
                                <div className={"row p-3"}>
                                    <button className={"btn btn-warning"}><BankTransferModal/></button>
                                </div>    
                                <div className={"row"}>
                                    or
                                </div>
                                <div className={"row p-3"}>
                                    <Link to={"confirmation-record"}>
                                        <button className={"btn btn-warning"} onClick={() => registration()}>Save group</button>
                                    </Link>
                                </div>                                               
                
                    </>
            }
            </div>
            <hr/>
            <div className={"summary-footer"}>
                <div className={"row"}>
                    <TermsOfSales />
                </div>
            </div>
               
</>
: 
<>

            <div className={"summary-header"}>
                Your group must contain at least two members before it can be registered.
            </div>
</>}
        </div>
    )
}

Payment.propTypes = {
    total: PropTypes.string.isRequired,
}

export default Payment
