import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useSelector} from "react-redux"

import "./summary/summary.scss"
import YourOrder from "./summary/your-order"
import Payment from "./summary/payment"
import {isDefined} from "../../utils/data"

const Summary = ({prevTab}) => {
    const { members } = useSelector(({groupReducer}) => groupReducer)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setTotal(0)
        members.forEach(member => {
            setTotal(prev => prev + (isDefined(member.amount) ? member.amount : (parseFloat(member.registered.registered_invoice))))
        })
    }, [members])  

    return (
        <div className={"content summary"}>
            <p className={"important-infos"}>Summary and payment</p>

            <div className={"row"}>
                <div className={"col-lg-6"}>
                    <YourOrder members={members} total={total}/>
                </div>
                <div className={"col-lg-6"}>
                    <Payment total={total}/>
                </div>
            </div>
            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
            </div>
        </div>
    )
}

Summary.propTypes = {
    prevTab: PropTypes.func.isRequired,
}

export default Summary
