import React, {useState} from "react"
import { FaEnvelope } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import "../../../../pages/union-member-single.scss"
import {onChangeByName, selectCategory} from "../../../../redux/addMember/addMemberAction"
import {checkMember} from "../../../../redux/api/apiAction"
import {isDefined} from "../../../../utils/data"
import Tab from "../../../../components/Tab/Tab"

const MemberConnection = ({pageActive, index, onNext}) => {
    const dispatch  = useDispatch()
    const {categories} = useSelector(({apiReducer}) => apiReducer)
    const errorAPI  = useSelector(({apiReducer}) => apiReducer.login_failed)
    const [success, setSuccess] = useState(false)
    const [error, setError]     = useState(false)
    const [email, setEmail]     = useState(process.env.NODE_ENV !== 'production' ? "isabella.annesi-maesano@inserm.fr" : "")
    const [validationEmail, setValidationEmail] = useState("")

    const onChange = e => {
        const {value} = e.target
        setEmail(value)
    }

    const checkValidation = () => {
        if(email === "")
            setValidationEmail("Pease enter email associated with your membership account")
        else
            setValidationEmail("")

        return email.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        if(checkValidation()) {
            dispatch(checkMember(email))
                .then(res => {
                    if(res === true) {
                        setEmail("")
                        setSuccess(true)
                        setError(false)
                        dispatch(selectCategory(categories,  "Union Member group"))
                        dispatch(onChangeByName("unionMember", true))
                        onNext()
                    }
                    else {
                        dispatch(selectCategory(categories, "Delegates group"))
                        dispatch(onChangeByName("unionMember", false))
                        setSuccess(false)
                        setError(true)
                    }
                })
        }
        else {
            dispatch(selectCategory(categories, "Delegates group"))
            dispatch(onChangeByName("unionMember", false))
            setSuccess(false)
            setError(true)
        }
    }

    return (
        <Tab pageActive={pageActive} index={index}>
            <div className={"union-member-single"}>
                <h2 className={"title"}>To register as a member of the UNION, please use the email associated with your membership account</h2>
                <form onSubmit={onSubmit}>
                    <div className="form-row">
                        <div className={"col-1"}>
                            <label><FaEnvelope/></label>
                        </div>
                        <div className={"col-11"}>
                            <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange} required/>
                            {validationEmail.length > 0 ?
                                <div className="invalid-feedback">
                                    {validationEmail}
                                </div> : ""}
                        </div>
                    </div>

                    <div className={"form-row"}>
                        {error ?
                            <div className="error invalid-feedback">
                                {isDefined(errorAPI) ? errorAPI.message : "Member not found"}
                            </div>
                            : ""}

                        {success ?
                            <div className="success valid-feedback">
                                Succeeded
                            </div>
                            : ""}
                    </div>

                    <div className={"form-footer " + (error ? "form-with-register" : "")}>
                        {error ? <button onClick={() => onNext()} className={"btn btn-info btn-round btn-register"}>Register as non member</button> : ""}
                        <button type={"submit"} className={"btn btn-info btn-round"}>Search</button>
                    </div>
                </form>
            </div>
        </Tab>
    )
}

export default MemberConnection
