import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaMapMarkerAlt, FaPenAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import {memberToForm, onChange, submitInformation} from "../../../../redux/addMember/addMemberAction"
import CountriesInput from "./form/countriesInput"
import PhoneInput from "../../../../components/form/phoneInput"
import {isDefined} from "../../../../utils/data"
import Tab from "../../../../components/Tab/Tab"
import ProfessionalInput from "./form/professionalInput_group"
import AlertCategory from "../../alertCategory/alertCategory"
import {getCategory} from "../../../../redux/api/apiAction"

const Form = ({pageActive, index, onPrev, onNext}) => {
    const dispatch  = useDispatch()
    const {members} = useSelector(({groupReducer}) => groupReducer)
    const {information, onEdit}             = useSelector(({addMemberReducer}) => addMemberReducer)
    const {categories, category, member}    = useSelector(({apiReducer}) => apiReducer)
    
    const disabledInput = isDefined(onEdit) ? onEdit : false
    
    useEffect(() => {
        if(category === null)
            if(isDefined(information.registered_category))
                dispatch(getCategory(information.registered_category.value))
    }, [])


    useEffect(() => {
        if(member !== null) {
            dispatch(memberToForm(member))
        }
    }, [member])

    const onSubmit = (e = null) => {
        e.preventDefault()
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.stopPropagation()
        }

        dispatch(submitInformation(onEdit, information, categories, category, members, onNext))
    }


    return (
        <Tab pageActive={pageActive} index={index}>
            <div className={"content about"}>
                <p className={"title"}>
                General information
                </p>
                <AlertCategory/>

                <p className={"important-infos txt-center"}>Please complete member profile before registering for the conference.</p>

                <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                    <div className="form-group">
                        <div className="form-row">
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaUser/></label>
                                <select className="form-control" name={"registered_gender"} value={information.registered_gender.value} onChange={e => dispatch(onChange(e))} required>
                                    <option value="" disabled selected>Title</option>
                                    <option>Mr</option>
                                    <option>Ms</option>
                                    <option>Mx</option>
                                    <option>Dr</option>
                                    <option>Prof</option>
                                </select>
                                {information.registered_gender.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_gender.error}</div>
                                    : ""}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaUser/></label>

                                <input type="text" className="form-control" placeholder="Firstname" name={"registered_firstname"} value={information.registered_firstname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                                {information.registered_firstname.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_firstname.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaUser/></label>
                                <input type="text" className="form-control" placeholder="Lastname" name={"registered_lastname"} value={information.registered_lastname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                                {information.registered_lastname.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_lastname.error}</div>
                                    : ""}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-row">
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaMapMarkerAlt/></label>
                                <input type="text" className="form-control" placeholder="Address" name={"registered_adress1"} value={information.registered_adress1.value} onChange={e => dispatch(onChange(e))} required/>
                                {information.registered_adress1.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_adress1.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaMapMarkerAlt/></label>
                                <input type="text" className="form-control" placeholder="Additional address" name={"registered_adress2"} value={information.registered_adress2.value} onChange={e => dispatch(onChange(e))} required={information.registered_adress2.required}/>
                                {information.registered_adress2.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_adress2.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                                <label><FaMapMarkerAlt/></label>
                                <input type="text" className="form-control" placeholder="Postal Code" name={"registered_zipcode"} value={information.registered_zipcode.value} onChange={e => dispatch(onChange(e))} required/>
                                {information.registered_zipcode.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_zipcode.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                                <label><FaMapMarkerAlt/></label>
                                <input type="text" className="form-control" placeholder="City" name={"registered_city"} value={information.registered_city.value} onChange={e => dispatch(onChange(e))} required/>
                                {information.registered_city.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_city.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                                <label><FaMapMarkerAlt/></label>
                                <CountriesInput value={information.registered_country.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} addMember={true}/>
                                {information.registered_country.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_country.error}</div>
                                    : ""}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-row">
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaEnvelope/></label>
                                <input type="email" className="form-control" placeholder="Email" name={"registered_mail"} value={information.registered_mail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                                {information.registered_mail.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_mail.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaEnvelope/></label>
                                <input type="email" className="form-control" placeholder="Confirm Email" name={"confirmEmail"} value={information.confirmEmail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                                {information.confirmEmail.error !== "" ?
                                    <div className="invalid-feedback">{information.confirmEmail.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaPhone/></label>
                                <PhoneInput value={information.registered_phone.value} onChange={e => dispatch(onChange(e))}/>
                                {information.registered_phone.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_phone.error}</div>
                                    : ""}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-row">
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaBuilding/></label>
                                <input type="text" className="form-control" placeholder="Organisation/Institution" name={"registered_organisation"} value={information.registered_organisation.value} onChange={e => dispatch(onChange(e))} required/>
                                {information.registered_organisation.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_organisation.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaBuilding/></label>
                                <input type="text" className="form-control" placeholder="Department" name={"registered_department"} value={information.registered_department.value} onChange={e => dispatch(onChange(e))} required/>
                                {information.registered_department.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_department.error}</div>
                                    : ""}
                            </div>
                            <div className={"col-12 col-md-6 input-group"}>
                                <label><FaPenAlt/></label>
                                <input type="text" className="form-control" placeholder="Current Position" name={"registered_function"} value={information.registered_function.value} onChange={e => dispatch(onChange(e))} required/>
                                {information.registered_function.error !== "" ?
                                    <div className="invalid-feedback">{information.registered_function.error}</div>
                                    : ""}
                            </div>
                            <ProfessionalInput value={information.professionalCtgSelect.value}
                                               onChange={e => dispatch(onChange(e))}
                                               valueTextInput={information.registered_profession_category.value}
                                               error={information.professionalCtgSelect.error}
                                               disabled={disabledInput}/>
                        </div>
                    </div>

                <div className={"content-footer"}>
                    <button className={"sr-btn-next btn btn-secondary"} onClick={() => onPrev()}>Previous</button>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
            </form>
        </div>
        </Tab>
    )
}

Form.propTypes = {
    pageActive: PropTypes.number.isRequired,
    index:  PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}

export default Form
